<template>
  <div class="w1004">
    <div class="mybetwrap">
      <ul class="tabs">
        <li class="tab-link current" data-tab="tab-1"><span>파워볼</span></li>
        <li class="tab-link" data-tab="tab-2"><span>파워사다리</span></li>
        <li class="tab-link" data-tab="tab-3"><span>스피드키노</span></li>
        <li class="tab-link" data-tab="tab-4"><span>키노사다리</span></li>
      </ul>

      <div id="tab-1" class="tab-content current">
        <div class="datesearch">
          <input type="date" value="2021-06-25">
          <select>
            <option selected>전체회차</option>
            <option>1회차</option>
            <option>2회차</option>
            <option>3회차</option>
            <option>4회차</option>
            <option>5회차</option>
            <option>6회차</option>
            <option>7회차</option>
          </select>
          <a class="bgf">검색</a>
        </div>

        <div class="betlistwrap">
          <ul class="betlisthead">
            <li>경기일시</li>
            <li>회차</li>
            <li>결과</li>
            <li>배당률</li>
          </ul>

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/powerball.png" />파워볼</li>
            </ul>
            <ul class="betlistconlist">
              <li>2021-06-29 15:45</li>
              <li>211</li>
              <li class="blc">파워볼 홀/짝 (홀)</li>
              <li>1.95</li>
            </ul>
            <ul class="betlistconlist">
              <li>2021-06-29 15:45</li>
              <li>211</li>
              <li class="blc">파워볼 홀/짝 (홀)</li>
              <li>1.95</li>
            </ul>
          </div><!-- betlistcon -->

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/powerball.png" />파워볼</li>
            </ul>
            <ul class="betlistconlist">
              <li>2021-06-29 15:45</li>
              <li>211</li>
              <li class="blc">파워볼 홀/짝 (홀)</li>
              <li>1.95</li>
            </ul>
            <ul class="betlistconlist">
              <li>2021-06-29 15:45</li>
              <li>211</li>
              <li class="blc">파워볼 홀/짝 (홀)</li>
              <li>1.95</li>
            </ul>
          </div><!-- betlistcon -->
        </div>

        <div class="boardpage mb20">
          <pagination :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      @goToPage="onCashListSearch" />
        </div>

      </div><!-- tab-1 -->

      <div id="tab-2" class="tab-content">
        <div class="datesearch">
          <input class="mr5" type="date" value="2021-06-25">
          <select class="mr5">
            <option selected>전체회차</option>
            <option>1회차</option>
            <option>2회차</option>
            <option>3회차</option>
            <option>4회차</option>
            <option>5회차</option>
            <option>6회차</option>
            <option>7회차</option>
          </select>
          <a class="bgf">검색</a>
        </div>

        <div class="betlistwrap">
          <ul class="betlisthead">
            <li class="w40">회차</li>
            <li class="w300">승(홈)</li>
            <li class="w40">무</li>
            <li class="w300">패(원정)</li>
          </ul>

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/powerLadder.png" alt="" />파워사다리</li>
              <li>2022-06-29 15:45</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
          </div><!-- betlistcon -->

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/powerLadder.png" alt="" />파워사다리</li>
              <li>2022-06-29 15:45</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
          </div><!-- betlistcon -->
        </div>

        <div class="boardpage mb20">
          <pagination :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      @goToPage="onCashListSearch" />
        </div>

      </div><!-- tab-2 -->

      <div id="tab-3" class="tab-content">
        <div class="datesearch">
          <input class="mr5" type="date" value="2021-06-25">
          <select class="mr5">
            <option selected>전체회차</option>
            <option>1회차</option>
            <option>2회차</option>
            <option>3회차</option>
            <option>4회차</option>
            <option>5회차</option>
            <option>6회차</option>
            <option>7회차</option>
          </select>
          <a class="bgf">검색</a>
        </div>

        <div class="betlistwrap">
          <ul class="betlisthead">
            <li class="w40">회차</li>
            <li class="w300">승(홈)</li>
            <li class="w40">무</li>
            <li class="w300">패(원정)</li>
          </ul>

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/speedKino.png" alt="" />스피드키노</li>
              <li>2022-06-29 15:45</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
          </div><!-- betlistcon -->

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/speedKino.png" alt="" />스피드키노</li>
              <li>2022-06-29 15:45</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
          </div><!-- betlistcon -->
        </div>

        <div class="boardpage mb20">
          <pagination :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      @goToPage="onCashListSearch" />
        </div>
      </div><!-- tab-3 -->

      <div id="tab-4" class="tab-content">
        <div class="datesearch">
          <input class="mr5" type="date" value="2021-06-25">
          <select class="mr5">
            <option selected>전체회차</option>
            <option>1회차</option>
            <option>2회차</option>
            <option>3회차</option>
            <option>4회차</option>
            <option>5회차</option>
            <option>6회차</option>
            <option>7회차</option>
          </select>
          <a class="bgf">검색</a>
        </div>

        <div class="betlistwrap">
          <ul class="betlisthead">
            <li class="w40">회차</li>
            <li class="w300">승(홈)</li>
            <li class="w40">무</li>
            <li class="w300">패(원정)</li>
          </ul>

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/kinoLadder.png" alt="" />키노사다리</li>
              <li>2022-06-29 15:45</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
          </div><!-- betlistcon -->

          <div class="betlistcon">
            <ul class="betlistconhead">
              <li><img src="@/assets/img/kinoLadder.png" alt="" />키노사다리</li>
              <li>2022-06-29 15:45</li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300 on"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
            <ul class="betlistconlist">
              <li class="w40">211</li>
              <li class="w815">
                <ul class="betlistconper">
                  <li class="ltscr w300"><span>CF파추카</span><span>2.26</span></li>
                  <li class="ctscr b0 w40"><span>VS</span></li>
                  <li class="ryscr w300 on"><span>3.4</span><span>과달하라 자바스</span></li>
                </ul>
              </li>
            </ul>
          </div><!-- betlistcon -->
        </div>

        <div class="boardpage mb20">
          <pagination :pageNum="pageInfo.page"
                      :pageSize="pageInfo.count_per_list"
                      :totalCount="pageInfo.tatal_list_count"
                      @goToPage="onCashListSearch" />
        </div>
      </div><!-- tab-4 -->

    </div><!-- mybetwrap -->
  </div>
</template>

<script>
import Pagination from '@/components/ui/Pagination'
export default {
  name: 'ResultSport',
  components: {
    Pagination
  }
}
</script>

<style scoped src="@/styles/common.css"></style>
<style scoped src="@/styles/subcommon.css"></style>
